.userDropdown {
  display: none;
}

.userDropdownBtn:hover .userDropdown {
  display: block;
  display: flex;
  flex-direction: column;
  transition: all 1s;
}
